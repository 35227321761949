exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aerotec-digital-tsx": () => import("./../../../src/pages/aerotec-digital.tsx" /* webpackChunkName: "component---src-pages-aerotec-digital-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nosotros-tsx": () => import("./../../../src/pages/nosotros.tsx" /* webpackChunkName: "component---src-pages-nosotros-tsx" */),
  "component---src-pages-panel-tsx": () => import("./../../../src/pages/panel.tsx" /* webpackChunkName: "component---src-pages-panel-tsx" */),
  "component---src-pages-productos-tsx": () => import("./../../../src/pages/productos.tsx" /* webpackChunkName: "component---src-pages-productos-tsx" */),
  "component---src-pages-promociones-tsx": () => import("./../../../src/pages/promociones.tsx" /* webpackChunkName: "component---src-pages-promociones-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/Product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

